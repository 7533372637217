.team {
    max-width:100%;
    display:flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    background-color: var(--background);
    transition: all 0.5s;
    color: var(--dark-text);
}

.team-top {
    transition: all 0.5s;
    color: var(--dark-text);
    margin-top: 30px;
    width:70%;
}

.team-title {
    text-align: left;
    font-size: 40px;
    margin-bottom: 30px;
}

.team-para {
    text-align:left;
    font-size:15px;
    border-left: 3px solid #4D6D9A;
    padding-left: 10px;
}

.team-subtitle {
    color: #FF6161;
    padding: 10px;
    text-decoration: underline #FF6161;

}

.people {
    display:flex;
    justify-content: center;
}

.alumni {
    text-align: left;
    display:inline-block;
}

.person {
    margin: 5px 10px;
    /* border: 1px solid #ccc; */
    height: 450px;
    position:relative;
}

.person h4 {
    height:50px;
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.person a {
    text-decoration: none;    
    color: #FF6161;
}

.person a:hover {
    text-decoration: none;    
    color: #999;
}

.person img {
    height: 240px;
    width: 240px;
    overflow:hidden;
    object-fit:contain;
    object-position: 5% 20%;
    border-radius: 50%;
}

.person p {
    text-align:center;
}

.person:hover {
    border: 1px solid #777;
}

.people-width .row {
    margin:20px 0px;
}

.people-width .col {
    margin:0px;
    padding:0px;
    display:flex;
    justify-content: center;
}

.person .person-footer {
    position:absolute;
    bottom:0px;
    width:100%;
    background-color: #e7e7e7;
    
}


.team-icon {
    color:dodgerblue;
    margin: 0px 10px;
    height:30px;
    width:30px;
}

.team-icon-invalid {
    color: #999;
    margin: 0px 10px;
    height:30px;
    width:30px;
}

.alumni-img {
    opacity:0.4;
    filter: grayscale(100%);
}