.data {
    max-width:100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--dark-text);
    background-color: var(--background);
    transition: all 0.5s;
    padding-bottom: 50px;
}

.data-top {
    color: #000000;
    margin-top: 30px;
    width:80%;
    color: var(--dark-text);
    transition: all 0.5s;

}

.lead-button {
    width: 200px;
    height: 50px;
    border-radius: 20px;
}

.flash {
    animation-name: highlight;
    animation-duration: 3s;
    animation-iteration-count: initial;
}

@keyframes highlight {
    from {
        background-color: 'blue';
    }

    to {
        background-color: none;
    }
}


.data-title {
    text-align: left;
    font-size: 40px;
    margin-bottom: 30px;
}

.data-para {
    text-align:left;
    font-size:15px;
    border-left: 3px solid #4D6D9A;
    padding-left: 10px;
}

.data-subtitle {
    text-align: left;
    font-size: 30px;
    margin: 10px 0px;
    margin-top: 30px;
}

.data-subtitle-mid {
    text-align: center;
    font-size: 30px;
    margin: 10px 0px;
}

.data-type {
    width:250px;
    border: 3px solid #4D6D9A;
    margin: 10px 100px;
    padding: 5px 0px;
    background-color: #1976d2;
    border-radius: 10px;
    color: #ffffff;
}

.data-type:hover {
    background-color:#1976d290;
    cursor: pointer;
    border: 3px solid #4D6D9A50;

}

.data-subpara {
    margin-top: 10px;
    text-align:left;
    font-size:15px;
}

.data-download-col {
    border-radius: 5px;
    border-top: 3px solid #4D6D9A;
    border-left: 3px solid #4D6D9A;
    margin: 10px 25px;
}

.data-download-col > h3 {
    text-align: center;
    margin:10px auto;
    text-decoration: underline;
}

.data-download-col > div {
    display:flex;
    justify-content:center
}

.download-icon {
    height:30px;
    width: 30px;
    margin: 10px 10px;
    color:#4D6D9A
}

.subscription-col {
    border-radius: 5px;
    border: 3px solid #4D6D9A;
    margin: 10px 25px;
    display:flex;
    justify-content:center;
    flex-direction: column;
}

.subscription-col > h3 {
    text-align: center;
    margin:10px auto;
    text-decoration: underline;
}

.paper-col {
    border-radius: 5px;
    border: 3px solid #4D6D9A;
    margin: 10px 25px;
    padding:10px;
    display:flex;
    flex-direction: column;
}

.subscription-button {
    width: max-content;
    margin: 20px;
    padding:20px;
}

.subscription-col > div {
    text-align: center;
    margin:20px auto;
}

.citation {
    font-size: 16px;
    color: var(--dark-text);
}

.paper {
    margin-top:10px;
    font-weight: 600
}

.cite-button {
    text-decoration: underline;
    margin-top:-10px;
}

.cite-button:hover {
    cursor:pointer;
}

.bibtex-code {
    color:#999999
}