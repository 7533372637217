.method {
    max-width:100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px;
    background-color: var(--background);
    transition: all 0.5s;
    color: var(--dark-text);
    align-items: center;
}

.method-top {
    transition: all 0.5s;
    color: var(--dark-text);
    margin-top: 30px;
    width:80%;
}

.method-top-row {
    display:flex;
    flex-wrap: nowrap;
}

.method-title {
    text-align: left;
    font-size: 40px;
    margin-bottom: 30px;
}

.method-para {
    text-align:justify;
    font-size:15px;
    border-left: 3px solid #4D6D9A;
    padding-left: 10px;
}

.method-main {
    width:80%;
    transition: all 0.5s;
    color: var(--dark-text);
    margin-bottom: 40px;
}

.method-subtitle {
    text-align: left;
    font-size: 30px;
    margin: 30px 0px;
}

.method-subpara {
    text-align:justify;
    font-size:15px;
}

.method-left-col {
    padding-right:50px;
}

.method-right-col {
 background-color: #4D6D9A;
 border-radius: 10px;
 padding:20px 30px;
 color:#ffffff;
}

.method-title-dark {
    text-align: left;
    font-size: 30px;
    margin-bottom: 20px;
}

.method-subtitle-dark {
    text-align: left;
    font-size: 15px;
}

.method-subpara-dark {
    text-align:left;
    font-size:15px;
}

.pdf {
    border-radius: 10px;
    width:100%;
    height: 500px;
}