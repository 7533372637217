.nav-container {
  max-width:100%;
  width:100%;
  display:flex;
  justify-content: center;
  background-color:#4D6D9A;
  z-index:10;
  border-bottom: 2px solid #e7e7e7;
}

.logo {
  height:50px;
  width:auto;
}

#basic-nav-dropdown{
  text-align:left;
}

.navBar {
  max-width:100%;
  color:#ffffff;
  width:80%;
}

.mr-auto {
  font-size:18px;
}

.mr-auto > a {
  width:max-content;
}

.navbar-collapse {
  justify-content: flex-end;
}
