.home {
    max-width:100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--background);
    transition: all 0.5s;
    color: var(--dark-text);
    padding: 0px;
}

.home-width {
    max-width:100%;
    justify-content: center;
    flex-direction: column;
    display:flex;
    align-items:center;

}

#canvas-container {
    height: 60vh;
    width: 70vw;
    position: relative;
    min-height: 400px;
 }

 canvas {
    min-height: 400px;
    min-width: 100%;
    width: 100%;
}

 @media (min-width: 768px) {
      canvas {
            height: auto;
            width: auto;
      }
 }



.home-graph-nav {
    max-width:100%;
    width:100%;
    justify-content: center;
    flex-direction: row;
    display:flex;
    align-items:center;
    /* background-color: var(--light-background); */
    /* color: var(--light-text); */
    margin:20px 0px;
}

.home-graph-nav-width {
    max-width:100%;
    width:80%;
    justify-content: center;
    flex-direction: row;
    display:flex;
    align-items:center;
}

.home-top {
    margin-top: 30px;
    width:80%;
}

.home-top-row {
    display:flex;
    flex-wrap: nowrap;
}

.home-top-row-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-para {
    text-align:left;
    font-size:15px;
    border-left: 3px solid var(--dark-background);
    padding-left: 10px;
}

.nav-left {
    font-size: 15px;
    color: var(--light-text);
    text-decoration: none;
}

.nav-right {
    font-size: 15px;
    color: var(--light-text);
    text-decoration: none;
}

.nav-left:hover, .nav-right:hover {
    color: var(--accent);
}

.nav-center {
    justify-content: center;
    flex:1;
    margin: 10px 20px;
    font-size:18px;
    align-items: center;
}

.home-nav-icon {
    margin-right: 1px;
}

.home-section {
    width: 80%;
    color:var(--dark-text);
}

.home-section-intro {
    padding-bottom: 10px;
    border-bottom: 3px solid #e7e7e7;
    margin: 20px 0px;
}


.home-section-title {
    text-align: left;
    font-size: 25px;
    margin-bottom: 30px;
}

.home-section-para {
    text-align:left;
    font-size:15px;
    border-left: 3px solid var(--dark-background);
    padding-left: 10px;
}

.home-section-options-title {
    text-align: left;
    font-size: 20px;
}

.reset-button {
    color: #BD3232;
    font-size: 13px;
    margin-left:10px;
    vertical-align: top;
}

.reset-button:hover {
    cursor:pointer;
}

.options {
    display: flex;
    justify-content: flex-start;
    color: var(--dark-text);
    position: relative
}

.options > * {
    color: var(--dark-text)
}

.MuiFormControl-root > label, .MuiFormControl-root > div {
    color: var(--dark-text);
    fill:white
}

.MuiSvgIcon-root {
    fill: var(--dark-text) !important
}

.canvasjs-chart-credit {
    display:none
}

.patch {
    position: relative;
    bottom: 10px;
    width: 4rem;
    height: 2rem;
    background-color: #fff;
}

.recharts-surface {
    overflow: visible
}


