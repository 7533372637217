.toggle-button {
  /* background-image: var(--gradient); */
  border: 3px solid var(--toogle-border);
  border-radius: 20px;
  cursor: pointer;
  font-size: 20px;
  justify-content: space-between;
  margin: 0 auto;
  overflow:hidden;
  position: relative;
  height:50px;
  transition: all 0.3s linear;
}

.toggle-container {
    margin-left: 30px;
}

.sunIcon, .moonIcon {
    transition: all 0.3s linear;
    position: relative;
    margin: 5px;
    color: #D2D2D2;
}

.sunIcon {
    margin-bottom: 30px;
}

.moonIcon {
    /* color: #fffd84; */
    /* translate: -40px -8px; */
}
